.app__work {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
  }
  
  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: black;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
  
    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }
  
    &.item-active {
      background-color: var(--secondary-color);
      color: #fff;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
    }
  }
  
  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .app__work-item {
    width: 270px;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    }
  
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
  
  .app__work-img {
    width: 100%;
    height: 230px;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin: 5px;
      font-weight: 800;
      font-family: var(--font-base);
      cursor: pointer;
      transition: all 0.2s;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  
    &:hover {
      opacity: 1;
    }
  }
  .app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
    }
  
    .app__work-tag {
      position: absolute;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background-color: #fff;
      top: -25px;
    }
  
    .app__work-description {
      margin-top: 1rem;
      line-height: 1.5;
      font-size: 14px;
      color: var(--gray-color);
    }
  
    .app__work-techstack {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
  
      span {
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
        background-color: var(--secondary-color);
        color: #fff;
        font-size: 12px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  
    .app__work-buttons {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .app__work-btn {
        border: none;
        border-radius: 10px;
        background-color: var(--secondary-color);
        color: #fff;
        font-size: 14px;
        font-weight: 800;
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
  
        &:hover {
          background-color: var(--primary-color);
        }
  
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }