#skills {
  margin-top: 150px;
}

.app__skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }

  h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--secondary-color);
  }
}

.app__skills-stat-badge {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.app__skills-stat-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  .app__skills-img {
    width: 100%;
    height: 190px;
    position: relative;

    img {
        width: 190px;
        height: 190px;
        border-radius: 50%;
        object-fit: cover;
    }
  }

  &:hover .app__skills-hover {
    opacity: 1;
  }
}

.app__skills-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0,0,0,0.5);
  border-radius: 50%;
  opacity: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.8);

      color: #fff;

      margin: 5px;
      font-weight: 800;
      font-family: var(--font-base);
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba(0,0,0,0.9);
      }

      svg {
          width: 80%;
          height: 80%;
          color: var(--white-color);
      }
  }
}

.app__skills-lang-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
}

.app__skills-lang-list li {
  font-size: 1.25rem;
  color: #24292e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f6f8fa;
  transition: all 0.2s ease-in-out;
}

.app__skills-lang-list li:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.app__skills-statistics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
    
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px;
      padding: 20px;
      background-color: var(--white-color);
      border-radius: 10px;
      box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
      transition: all 0.3s ease;
      color: #24292e;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 30px rgba(0,0,0,0.2);
      }
    
      span {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 10px;
      }
    
      p {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
        text-align: center;
      }
    }
  }
}