#contact {
    margin-top: 200px;
    .success-text {
        font-size: 30px;
    }
}
.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 2rem 2rem;

    .app__footer-card {
        min-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 1rem 1rem;
        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary-color-dark);
        transition: all 0.3s ease;

        img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;
        }

        p {
            font-weight: 600;
        }
        a {
            text-decoration: none;
        }

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }
}

.app__footer-form {
    width: 100%;
    flex-direction: column;

    h2 {
        margin-bottom: 30px;
    }

    div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;

        transition: all 0.3s ease;

        input, textarea {
            width: 100%;
            padding: 0.95rem;
            border: 0;
            background-color: var(--primary-color-dark);

            font-family: var(--font-base);
            color: var(--black-color);
            outline: none;
            max-width: 400px;
            border-radius: 10px;
        }

        textarea {
            height: 170px;
            resize: none;
        }
    }
    button {
        padding: 1rem 2rem;
        border-radius: 10px;
        border: 0;
        background-color: var(--secondary-color);
        color: var(--white-color);
        outline: none;
        margin: 10px 0;
        font-family: var(--font-base);
        cursor: pointer;
    }

}