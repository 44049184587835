.loading {
  display: flex;
  width:99vw;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  .loading-spinner {
    border: 16px solid var(--gray-color);
    border-top: 16px solid var(--secondary-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

#about {
  margin-bottom: 200px;

  .head-text {
    text-align: center;
    margin-bottom: 60px;
  }

  .app__profiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .app__profile-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    width: 90%;
    max-width: 1100px;

    .text-container {
      flex: 1;
      padding: 0 80px;
      text-align: center;
    }

    .img-container {
      @media only screen and (max-width: 790px) { 
        display: none;
      }

      flex: 1;
      height: 300px;
      background-position: center;
      background-size: cover;
      border-radius: 20px;
    }

    &:nth-child(even) {
      .text-container {
        padding-right: 0;
        padding-left: 80px;
        text-align: right;
      }
      .img-container {
        right: 0;
      }
    }
    &:nth-child(odd) {
      .text-container {
        padding-left: 0;
        padding-right: 80px;
        text-align: left;
      }
      .img-container {
        left: 0;
      }
    }
  }

  .bold-text {
    font-size: 36px;
    font-weight: 700;
    color: var(--brown-color);
    margin-bottom: 20px;
  }

  .p-text {
    font-size: 18px;
    color: var(--brown-color);
    line-height: 1.5;
    margin-bottom: 40px;
  }
}