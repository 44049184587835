#home {
    position: relative;
    background: url('../../assets/bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }

    margin-bottom: 200px;
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0;

    @media screen and (max-width: 550px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
}

.app__header-badge {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        background-color: #fff;
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
        
        span {
            font-size: 2.5rem;
        }
        p {
            font-size: 20px;
        }
        h1 {
            margin-left: 13px;
            font-weight: 400;
        }
    }
    .tag-cmp {
        flex-direction: column;
        margin-top: 2rem;
        
        p {
            width: 100%;
            text-transform: uppercase;
            text-align: center;
        }
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
    margin-top: -120px;
    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);

    }
    img {
        width: 70%;
        height: 70%;
    }

    div:nth-child(1) {
        width: 140px;
        height: 140px;

        img {
            width: 60%;
            height: 60%;
        }
    }
    div:nth-child(2) {
        margin-top: 2rem;
        margin-left: 1rem;
        width: 240px;
        height: 240px;
    }
    div:nth-child(3) {
        width: 100px;
        margin-left: -1rem;
        height: 100px;
    }
    div:nth-child(4) {
        width: 120px;
        height: 120px;
        margin-left: -6rem;
        margin-top: 12rem;
    }
    @media screen and (max-width: 550px) {
        display: none;
    }
    @media screen and (max-width: 1300px) {
        margin-top: 40px;
    }
}